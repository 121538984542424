import React from "react";
import Button from "../../shared/button";
import logo from "../../../assets/img/components/brand/nextgen_logo.png";

export default function BecomeNextgen() {
    return (
        <section data-aos="fade-up" data-aos-duration="3000" id="becomeNextgen">
            <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 local-header pxt-grid-standard content ic-40 xl:ic-80">
                <div className={`local-header--text col-12 lg:col-9`}>
                    <div className={`tc-blue tt-uppercase fw-bolder m-0 display-1`}>
                        <div className="count">
                            <p>
                                Werde 
                            </p>
                            <div>
                                <img src={logo} style={{maxWidth: "100%"}} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="local-header--button col-12 pt-20 grid">
                    <Button
                            text="Jetzt Kontakt aufnehmen"
                            gridTemplateAreas={`'. . text' '. grid2 .'`}
                            textBackgroundColor="black"
                            textColor="white"
                            iconColor="yellow"
                            link="https://utum.typeform.com/to/Hn4KEeuM"
                            target="_blank"
                            />
                </div>
                </div>
        </section>
    )
}